.flight-panel-container {
  position: absolute;
  z-index: 1000;
  right: 7px;
  top: 7px;
  width: 80px;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  .flight-panel-background {
    width: 100%;
    background-color: rgba(137, 137, 137, 0.5);
    height: 100%;
    border-radius: 15px;
    border: 2px solid #00000014;

    #nav-control {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    .MuiSlider-vertical.Mui-disabled {
      color: #1976d2 !important;
      margin: 0;
    }

    .MuiSlider-vertical {
      margin-top: 7px;
      height: calc(100% - 7px);
    }

    .drone-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 45px;
      bottom: 15px;
      position: absolute;
      left: 3px;
      color: white;

      .flight-max-height {
        font-size: 9pt;
      }

      .drone {
        background: url('../../../public/drone_black64.png') no-repeat;
        background-size: 45px 45px;
        content: '';
        width: 45px;
        height: 45px;
      }
    }

    .mark-text {
      font-size: 9pt;
      color: white;
      padding-top: 5px;
    }

    #nav-control {
      position: relative;
    }

    .flight-box-btuttons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      flex-direction: column;
    }

    .btn-map {
      color: #fff;
      background-color: #959595;
      margin: 5px 0;
      padding: 4px;
    }

    .btn-map:first-child {
      text-transform: lowercase;
    }
  }
}

.mapboxgl-ctrl button:not(:disabled):hover {
  background-color: #c8c8c8;
}

.mapboxgl-popup-close-button {
  padding: 3px 2px;
  font-size: 12pt;
}

.mapboxgl-popup-content {
  padding: 24px 10px 15px;
  overflow: auto;
  max-height: 300px;
}

.zone-info {
  padding: 5px;
  cursor: pointer;
  color: white;
  height: 1.5em;
}

.zone-info:hover {
  color: rgb(65, 65, 65);
}

.btn-icons {
  display: flex;
  flex-direction: column;
}

.slider-extra-padding {
  padding-right: 15px !important;
}