.kmlDownload {
	display: 'flex';
	flex-direction: 'column';
}

.kmlDownload__section {
	border-top: 3px solid #ccc;
	padding-top: 16px;
	margin-top: 6px;
}

.kmlDownload a.disabled {
	pointer-events: none;
	opacity: 0.6;
	cursor: not-allowed;
}

.kmlDownload a button:disabled {
	background-color: grey;
	color: white;
	cursor: not-allowed;
}

.kmlDownload__bigButton {
	width: 100%;
}

.kmlDownload__formLabel {
	color: #4a4a4a !important;
	margin-bottom:12px;
}

.kmlDownload__zonesTypes {
	margin-top: 6px;
	width: 100%;
}

.kmlDownload__zonesTypes__group {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
}

.kmlDownload__zonesTypes__itemCheckbox {
	padding-right: 4px;
	padding-top: 5px;
	padding-bottom: 5px;
}

.kmlDownload__zonesTypes__caption {
	position: absolute;
	top: -10px;
	left: 16px;
	background-color: white;
	padding: 0 4px;
}

.kmlDownload__zonesTypes__box {
	border: 2px solid;
	border-color: #1976d2;
	border-radius: 8px;
	padding: 10px;
	position: relative;
	margin-bottom: 20px;
}