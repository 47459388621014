$primary-link-color: rgb(0, 0, 238);
$button-color: #1976d2;
$button-color-hover: darken($button-color, 8%);

.no-decoration-link {
    color: $primary-link-color;
    text-decoration: none;

    &:visited {
        color: $primary-link-color;
    }
}

.no-decoration-link-button {
    background: none;
    border: none;
    color: $primary-link-color;
    text-decoration: underline;
    cursor: pointer;
    padding: 0;
    margin: 0;
    font-size: 1em;
}

.button-outlined-color {
    color: $button-color;
    border-color: $button-color;
    background-color: white;

    &:hover {
        color: $button-color-hover;
        border-color: $button-color-hover;
        background-color: white;
    }
}

.button-contained-color {
    background-color: $button-color;
    color: white;

    &:hover {
        background-color: $button-color-hover;
        color: white;
    }
}

.button-menu-shape {
    margin: 10px;
    width: 220px;
}

.hidden {
    display: none;
}