.map-options-container {
  position: absolute;
  z-index: 1000;
  left: 7px;
  top: 7px;
  padding-left: 0;
  padding-right: 7px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  #nav-control {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .MuiSlider-vertical.Mui-disabled {
    color: #1976d2 !important;
    margin: 0;
  }

  .MuiSlider-vertical {
    margin-top: 7px;
    height: calc(100% - 7px);
  }

  .drone {
    background: url('../../../public/drone_black64.png') no-repeat;
    background-size: 45px 45px;
    content: '';
    width: 45px;
    bottom: 15px;
    position: absolute;
    height: 45px;
    left: 3px;
  }

  .mark-text {
    font-size: 9pt;
    color: white;
  }

  #nav-control {
    position: relative;
  }

  .flight-box-btuttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-direction: column;
  }

  .btn-map {
    color: #fff;
    background-color: #959595;
    margin: 5px 0;
    padding: 4px;
  }

  .btn-map:first-child {
    text-transform: lowercase;
  }

  .mapboxgl-ctrl-geocoder--button {
    background: white !important;
  }
}

.mapboxgl-ctrl button:not(:disabled):hover {
  background-color: #c8c8c8;
}

.mapboxgl-popup-close-button {
  padding: 3px 2px;
  font-size: 12pt;
}

.mapboxgl-popup-content {
  padding: 24px 10px 15px;
  overflow: auto;
  max-height: 300px;
}

.btn-icons {
  display: flex;
  flex-direction: column;
  position: relative;

  .btn-icon {
    height: 44px;
    width: 44px;
    margin-bottom: 7px;
    color: white;
    background-color: rgba(137, 137, 137, 0.5);
    border: 1px solid #00000014;
    border-radius: 50%;
  }

  .btn-icon:active,
  .btn-icon:hover,
  .btn-icon.active {
    background-color: rgba(26, 118, 210, 0.5)
  }

  .mapboxgl-ctrl-geocoder {
    max-width: 400px;
    width: 50vw;
    min-width: 210px;
    transition: all .3s;

    .mapboxgl-ctrl-geocoder--icon {
      background: white;

      &:hover {
        background: white;
      }
    }

  }

  .collapsed-input {
    input {
      width: 0px;
      max-width: 0px;
      min-width: 0px;
      padding: 0px !important;
    }

    .mapboxgl-ctrl-geocoder {
      width: 0px;
      max-width: 0px;
      min-width: 0px;
    }

    svg {
      display: none;
    }
  }

  .search-input {
    position: absolute;
    top: 3px;
    left: 50px
  }
}