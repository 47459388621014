.mapboxgl-ctrl-top-right {
  background-color: #cfe8fc;
  border-radius: 25px;
  border: 2px solid #00000014;
  .mapboxgl-ctrl-geolocate{
      display: none;
  }
}
.mapboxgl-ctrl-group button {
  margin: 10px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 0 2px 'rgba(0 0 0 10%)';
}
.mapboxgl-ctrl-group:not(:empty) {
box-shadow: none;
}
.mapboxgl-ctrl-group {
  border-radius: 4px;
  background: none;
}

.mapboxgl-ctrl-geolocate {
  position: fixed;
  right: 5px;
  top: 5px;
  margin: 0 !important;
}

.marker {
  background-color: #ffffffb3;
  border: 1px solid #00000085;
  border-radius: 20px;
  padding: 2px;
  background-size: 90% !important;
  background-position: center;
}
.marker.foregin {
  border: 2px solid #1a76d2;
}
