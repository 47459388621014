.zones-details-overview {
  line-height: 1.3;
  padding-bottom: 10px;

  h4 {
    margin-top: 6px;
  }

  .pansa-icon {
    display: flex;
    justify-content: center;
    padding: 5px;
  }
}

.zones-details-overview__cordinates__copy-icon {
  padding-right: 4px;
  font-size: 0.8rem;
}

.zones-details-overview__cordinates__copy-icon:hover {
  cursor: pointer;
}

.zones-details-overview__cordinates b+b::before {
  content: ", ";
}

.zones-details-overview__cordinates a,
.zones-details-overview__cordinates a:visited {
  color: blue;
  text-decoration: none;
}

.accordion-summary-main {
  width: 100%;

  .box {
    display: flex;
    justify-content: space-between;
    line-height: 1.3;

    .box-title {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .box-icon {
      display: flex;
      align-items: center;
    }
  }
}

.MuiAccordionSummary-content {
  margin: 6px 1px;
}

.MuiAccordionDetails-root {
  line-height: 1.3;

  .icon {
    display: flex;
    align-items: center;
    padding: 4px 0;
  }
}

pre.notam-panel {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

.accordion-title {
  padding-top: 5px;
  padding-bottom: 5px;
}

.accordion-description {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}

.slaveZone {
  margin-left: 2rem;
}

.css-v84d5j-MuiSvgIcon-root {
  margin: 0 20px;
}

.activityHighlighted {
  font-weight: bold;
  color: #d7db1b;
}

.noActivityHighlighted {
  font-weight: bold;
  color: green;
}

.infoFrame {
  border: 2px solid black;
  padding: 5px;
}

.no-activty {
  color: green;
}