* {
  margin: 0;
  padding: 0;
}
h4,
p,
div {
  font-family: Arial, Helvetica, sans-serif;
}
.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.mapboxgl-ctrl-top-right {
  display: none;
}
.sidebar {
  background-color: rgba(78, 160, 40, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}
button > svg {
  max-width: 30px;
  max-height: 30px;
}
